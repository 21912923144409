import type { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import {
  build1120sBalanceSheet,
  Form1120sBalanceSheetRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheet";

export class Form1120sBalanceSheetGrouped extends AutoRendered<Form1120S[]> {
  constructor(data: Form1120S[]) {
    super(data);
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = build1120sBalanceSheet(form, startingColumn, startingRow);
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    const h = Form1120sBalanceSheetRowHeadings;
    return [
      "Year",
      h.Assets,
      h.TotalCurrentAssets,
      h.TotalNonCurrentAssets,
      h.TotalAssets,
      h.LiabilitiesAndShareholdersEquity,
      h.TotalCurrentLiabilities,
      h.TotalNonCurrentLiabilities,
      h.TotalLiabilities,
      h.TotalShareholdersEquity,
      h.TotalLiabilitiesAndEquity,
    ];
  }
  get percentageRowLabels(): string[] {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sBalanceSheetRowHeadings): number {
    const heading = Form1120sBalanceSheetRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
