import type { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import {
  build1120sIncomeStatement,
  Form1120sIncomeStatementRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatement";

export class Form1120sIncomeStatementGrouped extends AutoRendered<Form1120S[]> {
  constructor(public taxData: Form1120S[]) {
    super(taxData);
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = build1120sIncomeStatement(form, startingRow, startingColumn);
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels() {
    return [
      "Year",
      Form1120sIncomeStatementRowHeadings.NetRevenues,
      Form1120sIncomeStatementRowHeadings.GrossProfit,
      Form1120sIncomeStatementRowHeadings.TotalIncome,
      Form1120sIncomeStatementRowHeadings.TotalExpensesAndDeductions,
      Form1120sIncomeStatementRowHeadings.PreTaxProfitOrLoss,
      Form1120sIncomeStatementRowHeadings.TotalTax,
      Form1120sIncomeStatementRowHeadings.NetIncome,
    ];
  }

  get percentageRowLabels() {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sIncomeStatementRowHeadings): number {
    const heading = Form1120sIncomeStatementRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
